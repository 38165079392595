<template>
  <div class="decline" v-if="status && status.status">
    <b-card class="read-decline" no-body>
      <div class="content-decline" v-if="!isDeclined">
        <div class="head text-center">
          <img src="@/assets/images/svg/decline.svg" width="300" />
          <h5 class="mt-4">
            <strong>
              Você está recusando o convite para prestação de serviços!
            </strong>
          </h5>
          <p class="mx-5">Explica pra gente o motivo de sua recusa!</p>
        </div>
        <div class="body container mt-4">
          <b-form :form="form" @submit.prevent="handleSendJustification()">
            <b-form-radio
              v-model="$v.form.justification.$model"
              :class="$v.form.justification.$error && 'is-invalid'"
              class="pl-0"
              value="Período inadequado"
              name="justification"
            >
              Período inadequado
            </b-form-radio>
            <b-form-radio
              v-model="$v.form.justification.$model"
              :class="$v.form.justification.$error && 'is-invalid'"
              class="pl-0"
              value="Valor abaixo do esperado"
              name="justification"
            >
              Valor abaixo do esperado
            </b-form-radio>
            <b-form-radio
              v-model="$v.form.justification.$model"
              :class="$v.form.justification.$error && 'is-invalid'"
              class="pl-0"
              value="Localidade"
              name="justification"
            >
              Localidade
            </b-form-radio>
            <b-form-radio
              v-model="$v.form.justification.$model"
              :class="$v.form.justification.$error && 'is-invalid'"
              class="pl-0 mb-2"
              value="others"
              name="justification"
            >
              Outros
            </b-form-radio>
            <b-form-group
              label="Descreva o motivo"
              v-if="form.justification === 'others'"
            >
              <b-form-textarea
                class="text-area"
                v-model="justificationOthers"
              ></b-form-textarea>
            </b-form-group>
            <b-form-invalid-feedback
              v-if="!$v.form.justification.required"
              class="alert alert-danger"
              style="color: white"
            >
              Selecione um motivo da lista
            </b-form-invalid-feedback>
            <div class="actions">
              <router-link to="/invite-homologation/detail">
                <b-button variant="secondary">Voltar</b-button>
              </router-link>
              <b-button variant="primary" class="float-right" type="submit"
                >Enviar</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
      <thank-you v-if="isDeclined" />
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ThankYou from "./ThankYou";

export default {
  mixins: [validationMixin],
  components: {
    ThankYou,
  },
  data() {
    return {
      key: null,
      isDeclined: false,
      status: {},
      justificationOthers: null,
      form: {
        justification: null,
      },
      homologation: {},
    };
  },
  validations: {
    form: {
      justification: {
        required,
      },
    },
  },
  computed: {
    ...mapGetters(["user"]),
  },
  beforeMount() {
    document.body.style.background = "var(--bg)";
  },
  beforeDestroy() {
    document.body.style = "";
  },
  methods: {
    ...mapActions([
      "get_homologation",
      "decline_homologation",
      "get_status_notification",
    ]),
    handleSendJustification() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError && !this.$v.$anyError) {
        const form = { ...this.form };
        if (this.form.justification === "others") {
          form.justification = this.justificationOthers;
        }
        this.decline_homologation({
          key: this.key,
          justification: form.justification,
        }).then(() => {
          this.getStatus();
        });
      }
    },
    getStatus() {
      this.get_status_notification({ key: this.key }).then((response) => {
        this.status = response;
        this.isDeclined = this.status.status === 3;
      });
    },
  },
  mounted() {
    this.key = this.$route.query.key;
    this.getStatus();
  },
};
</script>
<style lang="scss" scoped>
.decline {
  color: $text-color;
  max-width: 550px;
  margin: 0 auto;
  .read-decline {
    overflow: hidden;
    padding: 3em 0;
    display: flex;
    margin: 2em 0;
    height: calc(100% - 7em);
    max-width: 800px;
  }
  .body {
    padding: 0 3em;
  }
  .text-area {
    border-color: $border-color !important;
  }
  .scope {
    border: 1px solid $border-color;
    padding: 1em;
    margin: 3em 1em 0;
    hr {
      border-top-color: $border-color;
    }
  }
}
</style>